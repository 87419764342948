export enum RoutePaths {
  // superview
  Platform = '/platform',
  Maintenance = 'maintenance',

  // views
  Dashboard = 'dashboard',
  NotificationReporting = 'notification-reporting',
  Clients = 'clients',
  Properties = 'properties',
  Companies = 'companies',
  MortgageProducts = 'mortgage-products',
  MortgageDocAi = 'mortgage-doc-ai',
  Lenders = 'lenders',
  Import = 'import',
  Users = 'users',
  Affiliates = 'affiliates',
  ReferralCodes = 'referral-codes',
  UserProfile = 'user-profile',

  // common
  BasicInfo = 'basic-info',
  Summary = 'summary',
  Settings = 'settings',
  Cases = 'cases',
  PersonalDetails = 'personal-details',
  Add = 'add',
  SearchPreferences = 'search-preferences',

  // referral code
  BulkCreate = 'bulk-create',

  // notifications
  AdviserUserNotificationSettings = 'user-notification-settings',
  NotificationConfiguration = 'notification-configuration',

  // clients
  FindClients = 'find-clients',
  InvalidClients = 'invalid-clients',

  // network
  TinyUrls = 'links',
  Networks = 'networks',
  NetworksComparisonSettings = 'network-comparison-settings',

  // admin tools
  BulkClientDelete = 'bulk-client-delete',
  BulkSetClientNotifications = 'bulk-set-client-notifications',
  EmailIdConvertor = 'email-id-convertor',
  ExportCompanyClients = 'export-company-clients',
  HomeReports = 'home-reports',
  FindCompanyOpportunities = 'find-company-opportunities',
  BulkReleaseOpportunities = 'release-opportunities',
  ImportCheckFile = 'import-check',
  OutcodeToRegion = 'outcode-to-region',
  ExportCompanyMortgages = 'export-company-mortgages',

  // import file steps
  UploadFiles = 'upload-files',
  CompareFiles = 'compare-files',

  // bulk client delete steps
  BulkClientDeleteCheckInvalidClients = 'check-invalid-clients',
  BulkClientDeleteClientsToDelete = 'clients-to-delete',

  // bulk set client notifications steps
  BulkNotificationsSelectClients = 'select-clients',
  BulkNotificationsSet = 'set-notifications',

  // export company clients steps
  ExportCompanyClientsSelectCompany = 'select-company',
  ExportCompanyClientsCompanyClientsExport = 'company-clients-export',

  // export company clients steps
  ExportCompanyMortgagesSelectCompany = 'select-company-mortgages',
  ExportCompanyMortgagesCompanyMortgagesExport = 'company-mortgages-export',

  // add property form pages
  FindProperty = 'findProperty',
  SolveExistingProperty = 'solveExistingProperty',
  CreateNewProperty = 'createNewProperty',
  SelectLender = 'selectLender',
  SolveExistingMortgage = 'solveExistingMortgage',
  FillMortgageDetails = 'fillMortgageDetails',
  MortgageSummary = 'mortgageSummary',
  MatchMortgages = 'matchMortgages',

  // find company opportunities steps
  FindCompanyOpportunitiesFindOpportunities = 'find-opportunities',

  // release opportunities steps
  BulkReleaseOpportunitiesSelectOpportunities = 'select-opportunities',
  BulkReleaseOpportunitiesInvalidOpportunities = 'invalid-opportunities',

  // client detail pages
  ClientEmploymentAndIncome = 'employment-and-income',
  ClientCreditReportSettings = 'credit-report-settings',
  ClientNotificationSettings = 'notification-settings',
  MortgageCaseDetail = 'mortgage-case',
  SmartSearchCaseDetail = 'smart-search-case',
  HelpCaseDetail = 'help-case',
  ClientProperty = 'property',
  ClientOverview = 'overview',
  ClientMortgage = 'mortgage',

  PropertyEquity = 'property-equity',
  CreditAgreements = 'credit-agreements',
  CreditRiskIndicators = 'risk-indicators',

  // company pages
  CompanyImportIntegrationSettings = 'import-integration-settings',
  CompanyUserInterfacesSettings = 'client-portal-settings',
  CompanyComparisonSettings = 'company-comparison-settings',
  CompanyCreditReport = 'credit-report',
  CompanyNetwork = 'company-network',
  CompanyPropertySettings = 'property-settings',

  // landing
  Landing = 'landing',
  Login = 'login',
  PasswordReset = 'password-reset',
  PasswordResetRequest = 'password-reset-request',
  Oauth2 = 'oauth2',

  // mortgages
  Mortgages = 'mortgages',
  MortgageAndCredit = 'mortgage-and-credit',
  MortgagePartsOverview = 'parts',

  // Cases
  OpportunitiesApprovalRequired = 'approval-required',
  Opportunities = 'opportunities',
  CasesLeads = 'leads',
  CasesPipeline = 'pipeline',
  CasesClosed = 'closed',

  // User
  UserManagement = 'management',
  Permissions = 'permissions',

  // Role
  Admin = 'admin',
  Adviser = 'adviser',
  Manager = 'manager',
  Owner = 'owner',

  // pre-completions
  PreCompletions = 'pre-completions',
  Comparison = 'comparison',

  // Lenders
  Criteria = 'criteria',
  Svr = 'svr',

  // Lender group
  LenderGroups = 'lender-groups',
}
